<template>
    <div>
        <div class="row my-3">
            <h1 class="col-sm-12">Configuración</h1>
        </div>

        <DataGrid :configuracion="datagrid_config" :data="configuraciones" @ready="buscar_btns">
            <button class="btn btn-secondary mr-2" @click="listar_configuraciones"> <i class="fa-solid fa-rotate"></i> Actualizar</button>
            <button class="btn btn-primary" @click="show_modal = true"> <i class="fa-solid fa-plus"></i> Crear</button>
        </DataGrid>

        <Form v-if="show_modal" :configuracion="configuracion" @close="cerrar_modal" @update="cerrar_modal(true)" />

    </div>
</template>

<script>

import DataGrid from '@/components/DataGridV3.vue';
import Form from './Configuracion/Form.vue';
import apiCondusef from '@/apps/condusef/api/configuracion';


const configuraciones_disable = ['REDECO_SUPER_USUARIO', 'REDECO_SUPER_USUARIO_TOKEN', 'REDECO_SUPER_USUARIO_PASSWORD', 'REDECO_API_URL'];

export default {
    components: { DataGrid, Form },
    data() {
        return {
            datagrid_config: {
                cols: {
                    nombre: 'Nombre',
                    valor: 'Valor',
                    tipo: 'Tipo',
                    descripcion: 'Descripción',
                    estatus: 'Estatus',
                    opciones: 'Opciones'
                },
                mutators: {
                    valor(val, row, vue) {

                        if (typeof val === 'string' && val.length > 40) {
                            return val ? val.substr(0, 6) + '**********' : val;
                        } else {
                            return val;
                        }
                    },
                    estatus(val, row, vue) {
                        return val == 1 ? 'Activo' : 'Inactivo'
                    },
                    opciones(val, row, vue) {
                        return '<button class="btn btn-sm btn-primary mr-2 btn-edit" data-config="' + vue.$helper.toJson(row) + '" data-accion="editar"><i class="fa-regular fa-pen-to-square"></i></button>'
                    }
                },
                selector: false,
            },
            configuraciones: [],
            configuracion: null,
            show_modal: false,
            configuraciones_disable,
        }
    },
    mounted() {
        this.listar_configuraciones();
    },
    methods: {
        async listar_configuraciones() {
            try {
                this.configuraciones = (await apiCondusef.listar_configuraciones()).data;
            } catch (error) {
                this.$log.error('error', error);
                this.$helper.showAxiosError(error, 'Error');
            }
        },
        buscar_btns() {
            document.querySelectorAll('button.btn-edit').forEach(btn => {
                btn.removeEventListener('click', this.manejarAccion);
                btn.addEventListener('click', this.manejarAccion);
            });
        },
        manejarAccion(e) {

            let btn = e.target;

            if (btn.tagName == 'I')
                btn = btn.parentElement;

            let configuracion = this.$helper.fromJson(btn.dataset.config);

            this.configuracion = configuracion
            this.show_modal = true;
        },
        cerrar_modal(recargar) {
            if (typeof recargar == ' undefined')
                recargar = false

            this.show_modal = false;
            this.configuracion = null

            if (recargar)
                this.listar_configuraciones();
        }
    },
};
</script>